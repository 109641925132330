import React, { useRef, useState } from "react";
import { Styling } from "./styled.js";
import Play from "../../../../../images/play.inline.svg";
import { Link } from "gatsby";

export default function CampaignPage2Hero({ data, slug }) {
  const trailerVid = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayVideo = () => {
    if (isPlaying === false) {
      trailerVid.current.play();
      setIsPlaying(true);
    } else {
      trailerVid.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <Styling>
      <div className="wrapper">
        <h1 class="heading">{data.page2Section1Heading}</h1>
        <div className="video-wrapper">
          <div className={isPlaying ? `play playing` : `play paused`}>
            <button onClick={() => handlePlayVideo()}>
              <Play />
            </button>
          </div>
          <video playsInline ref={trailerVid} src={data.page2Section1Video}></video>
        </div>
      </div>
    </Styling>
  );
}
