import styled from "styled-components";

const Styling = styled.div`
  color: var(--black);
  padding-bottom: 4rem;
  background: var(--white);

  .wrapper {
    position: relative;
    background: var(--white);
    z-index: 10;
    padding-block: 4rem;
    padding-inline: 2rem;
  }

  .heading {
    margin-bottom: 4rem;
    text-transform: uppercase;
    ${({ theme }) => theme.tomato("regular")}
    text-transform:uppercase;
    font-size: clamp(5rem, 3.5vw, 12rem);
    letter-spacing: -0.07em;
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 3rem;
  }

  .text,
  .button,
  .notice {
    position: relative;
    margin-bottom: 2rem;
  }
  .notice {
    color: #d02600;
    font-weight: bold;
  }
`;

export { Styling };
