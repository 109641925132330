import styled from "styled-components";

const Styling = styled.div`
  .wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
`;

export { Styling };
