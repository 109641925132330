import styled from "styled-components";

const Styling = styled.div`
  background: var(--black);
  color: var(--white);
  padding-top: 16rem;
  position: relative;

  &:before {
    width: 100%;
    content: "";
    height: 20%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--white);
    z-index: 0;
  }

  .wrapper {
    z-index: 10;
    position: relative;
  }

  .heading {
    margin-bottom: 4rem;
    text-transform: uppercase;
    ${({ theme }) => theme.tomato("regular")}
    text-transform:uppercase;
    font-size: clamp(5rem, 3.5vw, 12rem);
    letter-spacing: -0.07em;
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 3rem;
    padding: 0 2rem;
  }

  .text {
    padding: 0 2rem;
    margin-bottom: 4rem;
  }

  .video-wrapper {
    width: 100%;
    position: relative;
    /* min-height: 50vh; */
    /* margin-bottom: 4rem; */

    button {
      padding: 0;
      border: 0;
    }

    .play {
      ${({ theme }) => theme.easing("0.5")}
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      cursor: pointer;

      &.playing {
        transform: scale(3);
        opacity: 0;
      }

      &:hover {
        svg {
          path {
            fill: var(--black);
          }
        }
      }

      svg {
        z-index: 10;
        max-width: 100px;

        path {
          fill: var(--white);
          ${({ theme }) => theme.easing("0.5")}
        }
      }
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: ${(props) => (props.introVideoObjectFit ? props.introVideoObjectFit : "cover")};
      display: flex;
      /* aspect-ratio: 16 / 9; */
    }
  }
`;

export { Styling };
