import React from "react";
import { graphql } from "gatsby";
import { useLocation } from "@reach/router";
import CampaignPage2Hero from "../components/page-components/Campaigns/Page2/Hero";
import CampaignPage2Section2 from "../components/page-components/Campaigns/Page2/Page2Section2";
import { Styling } from "../components/page-components/Campaigns/Page2/styled.js";

export default function Campaigns2({ data }) {
  const { pageData, slug } = data.wpCampaign;
  return (
    <Styling>
      <CampaignPage2Hero data={pageData} />
      <CampaignPage2Section2 data={pageData} slug={slug} />
    </Styling>
  );
}

export const query = graphql`
  query ($id: String!) {
    wpCampaign(id: { eq: $id }) {
      seo {
        metaDesc
        title
      }
      slug
      pageData: acf_campaigns {
        page2Section1Heading
        page2Section1Video
        page2Section2Heading
        page2Section2Text
        page2Section2Notice
        page2Section2Trustpilot {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
