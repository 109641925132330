import React from "react";
import { Styling } from "./styled.js";
import parse from "html-react-parser";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export default function CampaignPage2Section2({ data, slug }) {
  console.log(data);
  return (
    <Styling>
      <div className="wrapper">
        <div className="heading">{data.page2Section2Heading}</div>
        <div className="text">{parse(data.page2Section2Text)}</div>
        <div className="buttons">
          <Link to={`/campaign/${slug}-3`} className="fakeButton button capsule_button red artist-development-signup-button">
            Sign up for a free consultation
          </Link>
        </div>
        <div className="notice">{data.page2Section2Notice}</div>
        <GatsbyImage class="trustpilot" image={data.page2Section2Trustpilot.localFile.childImageSharp.gatsbyImageData} />
      </div>
    </Styling>
  );
}
